<template>
  <div>
    <div class="card">
      <div v-if="transactions.length" class="my-transactions">
        <h5>Transactions</h5>
        <DataTable
          :value="transactions"
          dataKey="id"
          v-model:transactions="transactions"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="rows"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column
            header="id"
            field="id"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>

          <Column
            header="addressFrom"
            field="addressFrom"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column
            header="addressTo"
            field="addressTo"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column
            header="createdAt"
            field="createdAt"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column header="hash" field="hash" style="word-break: break-all">
          </Column>

          <Column
            header="methodName"
            field="methodName"
            style="word-break: break-all"
          >
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-transactions">
        <p>Not any transactions yet</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transactions',
  head() {
    return {
      title: 'Transactions',
    }
  },
  data() {
    return {
      transactions: {},
      item: {},
      openDialog: false,
      sendDialog: false,
      saleDialog: false,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    this.getTransactions()
  },
  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getTransactions()
    },
    async getTransactions() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/transactions', {
          headers,
          params,
        })
        this.transactions = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/transactions api call',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>
